<template>
    <div class="home">
        <p class="mb-4">
        <section class="hero is-primary">
            <div class="hero-body">
                <p class="title">
                    Small Invoice - MS Teams Dashboard
                </p>
                <p class="subtitle">
                    Globale Produktsuche
                </p>
            </div>
        </section>
        </p>
        <div class="tile is-ancestor mb-4">
            <div class="tile is-4 is-vertical is-parent">
                <div class="tile is-child box">
                    <div>
                        <article class="panel is-primary">
                            <p class="panel-heading">
                                Such-Parameter
                            </p>
                            <div class="mt-4 mb-4 ml-2 pb-4">
                                <h6 class="title is-6">Datumsbereich: {{ datefirst}} - {{ datelast }}</h6>
                                <p>Anzahl Produkte: {{ users.length}}</p>
                                <p>Seite: {{ paginationdata.page }} / {{ paginationdata.pages }}</p>
                            </div>
                        </article>
                    </div>
                    <div class="columns mt-4">
                        <div class="column">
                            <div class="columns ">
                                <div class="column">
                                    <button :disabled="loadingdata? true : false " class="button is-primary"
                                        @click="searchProducts('https://api.smallinvoice.com/v2/receivables/invoices?sort=-number&limit=200&with=positions,contact_name&offset=0')"><i
                                            class="fas fa-angle-double-left"></i></button>
                                </div>
                                <div class="column">
                                    <button :disabled="loadingdata? true : false " class="button is-primary"
                                        @click="searchProducts(paginationdata.previous)"><i
                                            class="fas fa-angle-left"></i></button>
                                </div>
                                <div class="column">
                                    <button :disabled="loadingdata? true : false " class="button is-primary"
                                        @click="searchProducts(paginationdata.next)"><i
                                            class="fas fa-angle-right"></i></button>
                                </div>

                                <div class="column">
                                    <button :disabled="loadingdata? true : false " class="button is-primary"
                                        @click="searchProducts(paginationdata.last)"><i
                                            class="fas fa-angle-double-right"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field has-addons">
                                <div class="control">
                                    <input class="input" type="number" min="1" v-bind:max="paginationdata.pages"
                                        v-model="chosenPage" style="maxWidth:75px;">
                                </div>
                                <div class="control">
                                    <a class="button is-primary" @click="goToPage(chosenPage)">
                                        GO
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <progress v-if="loadingdata" class="progress is-small is-primary" max="100">15%</progress>

                </div>
                <div class="tile is-child box">
                    <table class="table table is-striped table is-narrow table is-fullwidth">

                        <thead>
                            <tr>
                                <th class="has-text-centered"><abbr>RG-Nr.</abbr></th>
                                <th class="has-text-centered"><abbr>PDF</abbr></th>
                                <th class="has-text-centered"><abbr>Vorschau</abbr></th>
                                <th class="has-text-centered"><abbr>Datum</abbr></th>
                                <th class="has-text-centered"><abbr>Seiten</abbr></th>
                                <th class="has-text-centered"><abbr>Betrag</abbr></th>
                                <th class="has-text-centered"><abbr>Status</abbr></th>

                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="item in chosenInvoice" :key="item.number">
                                <th class="has-text-centered">{{ item.number }}</th>
                                <th class="has-text-centered"><span class="icon is-small" @click="downloadPDF(item.id)"
                                        v-bind:title="'Invoice_'+item.id+'.pdf'"><i class="far fa-file-pdf"
                                            aria-hidden="true"></i></span></th>
                                <th class="has-text-centered"><span class="icon is-small"
                                        @click="previewRG(item.id, 1, item.page_amount)"
                                        v-bind:title="'Invoice_'+item.id+'.pdf'"><i class="fas fa-search"></i></span>
                                </th>
                                <td class="has-text-centered">{{ item.date }}</td>
                                <td class="has-text-centered">{{ item.page_amount }}</td>
                                <td class="has-text-centered">sFr. {{ item.total }}</td>
                                <td class="has-text-centered" v-if="item.status ==='P' " bgcolor="lightgreen">Bezahlt
                                </td>
                                <td class="has-text-centered" v-if="item.status ==='DR'" bgcolor="lightgrey">Entwurf
                                </td>
                                <td class="has-text-centered" v-if="item.status ==='D' " bgcolor="black"
                                    style="color: red;">Geloescht</td>
                                <td class="has-text-centered" v-if="item.status ==='S' " bgcolor="lightblue">Gesendet
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="tile is-parent">
                <div class="tile is-child box">
                    <div class="tabs is-left is-boxed is-small">
                        <ul>
                            <li v-bind:class="{ 'is-active' : !rgVorschau}">
                                <a>
                                    <span class="icon is-small"><i @click="rgVorschau=false" class="fas fa-list"
                                            aria-hidden="true"></i></span>
                                    <span @click="rgVorschau=false">Produkte</span>
                                </a>
                            </li>
                            <li v-bind:class="{ 'is-active' : rgVorschau}">
                                <a>
                                    <span class="icon is-small"><i @click="rgVorschau=true" class="fas fa-search"
                                            aria-hidden="true"></i></span>
                                    <span @click="rgVorschau=true">RG Vorschau</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div v-if="!rgVorschau">
                        <div style="minHeight: 450px;">
                            <input class="input is-primary" type="text" placeholder="Suchfilter"
                                v-model="filters.name.value" />
                            <v-table class="table table is-striped table is-narrow table is-fullwidth" :data="users"
                                :filters="filters" :currentPage.sync="currentPage" :pageSize="10"
                                @totalPagesChanged="totalPages = $event">
                                <thead slot="head">
                                    <th>Datum</th>
                                    <th>Artikel</th>
                                    <th>Kunde</th>
                                </thead>
                                <tbody slot="body" slot-scope="{displayData}">
                                    <tr v-for="row in displayData" :key="row.guid"
                                        @click="selectRow(row.id, row.number, row.date, row.total, row.status, row.rgid, row.page_amount) "
                                        :class="{'is-selected has-background-grey-lighter has-text-black': (row.id == RowSelect)}">
                                        <td>{{ row.date }}</td>
                                        <td>{{ row.name }}</td>
                                        <td>{{ row.contact_name }}</td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </div>
                        <div class="">
                            <div class="navbar">
                                <smart-pagination :currentPage.sync="currentPage" :totalPages="totalPages"
                                    :directionLinks="directionLinks" :maxPageLinks="maxPageLinks" />
                            </div>
                        </div>
                    </div>
                    <div v-if="rgVorschau">
                        <div class="container">
                            <div v-for="item in chosenInvoice" :key="item.number">
                                <div class="buttons">
                                    <button @click="previewRG(item.id, index, item.page_amount)"
                                        v-for='index in item.page_amount' :key='index'
                                        class="button is-primary is-light">{{index}}</button>
                                    <button
                                        @click="rgVorschau=false, chosenInvoice=[], RowSelect='',src='data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'"
                                        class="button is-danger is-light">X</button>
                                </div>
                            </div>
                            <img :src="src" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import SI_API from "../api/Small_Invoice_API.js";
import download from "downloadjs";
export default {
  components: {},
  data() {
    return {
      rgVorschau: false,
      token: "",
      chosenInvoice: [],
      contacts: [],
      datefirst: "",
      datelast: "",
      chosenPage: 1,
      paginationdata: [],
      searchload: "",
      loadingdata: false,
      RGSelect: "",
      RowSelect: "",
      localpages: "",
      users: [],
      url: "https://",
      filters: {
        name: { value: "", keys: ["name"] },
      },
      currentPage: 1,
      totalPages: 0,
      directionLinks: true,
      maxPageLinks: 15,
      src: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
    };
  },
  name: "search",

  async created() {
    this.searchProducts(
      "https://api.smallinvoice.com/v2/receivables/invoices?sort=-number&limit=200&with=positions,contact_name&offset=0"
    );
  },
  methods: {
    sortBy: function (sortKey) {
      this.reverse = this.sortKey == sortKey ? !this.reverse : false;
      this.sortKey = sortKey;
    },
    goToPage(payload) {
      

      if (
        payload > this.paginationdata.page &&
        payload < this.paginationdata.pages
      ) {
        let url =
          "https://api.smallinvoice.com/v2/receivables/invoices?sort=-number&limit=200&with=positions,contact_name&offset=" +
          (payload - 1) * 200;
        this.searchProducts(url);
      } else {
        this.chosenPage = this.paginationdata.page;
      }
    },
    selectRow(payload, number, date, total, status, rgid, page_amount) {
      this.RowSelect = payload;
      this.chosenInvoice = [];
      this.chosenInvoice.push({
        id: rgid,
        number: number,
        date: date,
        status: status,
        total: total,
        page_amount: page_amount,
      });
      
    },
    searchProducts(searchurl) {
      this.loadingdata = true;
      this.users = [];
      SI_API.GetNewToken(this.$route.query.clientid).then((response) => {
        this.token = response;
        SI_API.SearchInvoices(response, searchurl).then(
          ((res) => {
            let dataarray = [];
            let datatemp = [];
            let datatemp2 = [];
            let globalindex = 0;
            for (let index = 0; index < res.data.items.length; index++) {
              datatemp = res.data.items[index];
              for (
                let index2 = 0;
                index2 < datatemp.positions.length;
                index2++
              ) {
                datatemp2 = datatemp.positions[index2];
                globalindex++;
                if (datatemp2.catalog_type == "P" && datatemp2.name != "") {
                  dataarray.push({
                    id: globalindex,
                    rgid: datatemp.id,
                    number: datatemp.number,
                    date: datatemp.date,
                    name: datatemp2.name,
                    contact_name: datatemp.contact_name,
                    status: datatemp.status,
                    total: datatemp.total,
                    page_amount: datatemp.page_amount,
                  });
                }
              }
            }
            this.paginationdata = res.data.pagination;
            this.users = dataarray;
            this.datefirst = dataarray[0].date;
            this.datelast = dataarray[dataarray.length - 1].date;
            this.loadingdata = false;
            this.chosenPage = this.paginationdata.page;
          }).bind(this)
        );
      });
    },
    downloadPDF(payload) {
      SI_API.GetPDFPath(this.token, payload).then((response) => {
        download(response, "Invoice_" + payload + ".pdf", "application/pdf");
      });
    },
    previewRG(payload, page, pages) {
      SI_API.GetPreview(this.token, payload, page).then((response) => {
        var bytes = new Uint8Array(response.data);
        var binary = bytes.reduce(
          (data, b) => (data += String.fromCharCode(b)),
          ""
        );
        this.src = "data:image/jpeg;base64," + btoa(binary);
        this.rgVorschau=true
      });
    },
  },
};
</script>

<style>
</style>